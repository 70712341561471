import { clusterToAccountId, getUserDefaultClusters } from './accountIdMapping';

export async function userAccessibleCluster(platformClient, email, userAccountId) {
  try {
    await platformClient.get(`https://api.cimpress.io/auth/access-management/v1/principals/${email}/permissions/data-discovery-metadata/*/admin`);
    // User is an admin. return all possible cluster
    return Object.keys(clusterToAccountId);
  } catch (error) {
    let usersClusters = getUserDefaultClusters(userAccountId);
    if (usersClusters.length <= 0) {
      throw new Error("user's account is not registered in data discovery");
    }
    return usersClusters;
  }
}

