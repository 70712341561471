export const clusterToAccountId = {
  'cimpress.eu-west-1': [ 'g2Ez5VaoZWoqU22XqPjTLU', 'xu2HQoHLSAV1aSU571yCdn' ],
  'printdeal.eu-west-1': 'noS5wU4XH2WRmkaqKqTpfH',
  'cimpressvcs.eu-west-1': 'azG5AKjW8jcdAPYh5X9H8K',
  'vistaprint.eu-west-1': 'ozoDdrmewShEcbUDWX8J3V',
  'nationalpen.eu-west-1': '4W1CB1YZxupiBsB5XExuc4',
  'tradeprint.eu-west-1': 'ca7c3Pxtzhrgm7i4obvTTh',
  'exagroup.eu-west-1': 'aofiyWQt9jjC1YsFUdEoAs',
  'druck.eu-west-1': '7xnyM3eU1yTsjMie4VTRXm',
  'buildasign.eu-west-1': 'mUushi4JkEaX1E1bp3xSm7',
  'pixartprinting.eu-west-1': 'sUwJnco1V7RtqbL6W8DuQ8',
  'wmd.eu-west-1': 'p9h7ZCQf4m5AEeWDGq8zFT',
  'boxup.eu-west-1': 'nonhZxAHRH4Z7PMWBKtwwC',
  'printi.eu-west-1': 'qwhj8J5SMLUVpVZbWyAriJ'
};

export function getUserDefaultClusters(userAccountId) {
  let result = [];
  for (const [cluster, accountIds] of Object.entries(clusterToAccountId)) {
    if (Array.isArray(accountIds) && accountIds.includes(userAccountId)) {
      result.push(cluster);
    } else if (accountIds === userAccountId) {
      result.push(cluster);
    }
  }
  return result;
}
